<template>
  <TheDialog :open="props.open">
    <template #header>
      <TheDialogHeader
        :title="t('title-send-support-request')"
        @click="$emit('modal:close')"
      />
      <UiDialogDescription />
    </template>
    <template #content>
      <div class="relative flex flex-col w-full p-6 overflow-hidden">
        <div class="text-regular-18">
          {{
            props.dialogType === ContactRequestType.SUPPORT
              ? t('text-support-dialog')
              : t('text-invoice-dialog')
          }}
        </div>
        <form
          class="flex flex-col items-center justify-center w-full h-full mt-4"
          @submit="onSubmit"
        >
          <FormField v-slot="{ componentField }" name="message" class=""
            ><UiFormItem class="">
              <UiFormControl>
                <UiTextarea
                  v-model="messageText"
                  type="text"
                  placeholder=""
                  v-bind="componentField"
                  class="'w-full mt-8 !bg-iq-black !min-h-[160px] !border-iq-beige !text-iq-beige'"
                />
              </UiFormControl>
              <UiFormMessage />
            </UiFormItem>
          </FormField>

          <UiButton
            :disabled="isLoadingContactRequest || isEmpty"
            class="h-12.5 iq-button-success lg:max-w-[60%] justify-self-center lg:col-span-full mt-12 lg:mt-16 iq-button place-self-end"
          >
            <span v-if="!isLoadingContactRequest">{{ t('lbl-send') }} </span>
            <TheSpinner v-if="isLoadingContactRequest" />
          </UiButton>
        </form>
      </div>
    </template>
  </TheDialog>
</template>

<script setup lang="ts">
import { FormField } from '@/components/ui/form';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { toast } from 'vue-sonner';
import { z } from 'zod';
import { ContactRequestType } from '~/enum/contact-request-type.enum';

const { t } = useI18n({
  useScope: 'local',
});
const isLoadingContactRequest = ref(false);
const recaptcha = useReCaptcha();
const userStore = useUserStore();
const messageText = ref('');

const props = defineProps<{
  open: boolean;
  dialogType: ContactRequestType;
}>();
const emit = defineEmits(['modal:close']);

const isEmpty = computed(() => {
  return messageText.value.trim() === '';
});

const formSchema = toTypedSchema(
  z.object({
    message: z.string({ required_error: t('message.required') }),
  })
);

const { handleSubmit, resetForm } = useForm({
  validationSchema: formSchema,
});

const onSubmit = handleSubmit(async (values) => {
  const { message } = values;

  try {
    isLoadingContactRequest.value = true;

    await recaptcha?.recaptchaLoaded();
    const token = await recaptcha?.executeRecaptcha('contact');
    await $fetch('/api/contact', {
      body: {
        name: userStore.user?.name,
        email: userStore.user?.email,
        message,
        type: props.dialogType,
        organizationId: userStore?.user?.organizationId,
      },
      method: 'POST',
      headers: { 'X-Recaptcha-Token': token ?? '' },
      retry: false,
    });

    resetForm();
    messageText.value = '';
    emit('modal:close');

    toast(t('toast-send-success'));
  } catch {
    clearError();
    toast.error(t('toast-general-error'), {
      duration: 1000000,
    });
  } finally {
    isLoadingContactRequest.value = false;
  }
});
</script>

<i18n lang="json">
{
  "de": {
    "title-send-support-request": "Supportanfrage senden",
    "text-support-dialog": "Bei Fragen rund um unsere Dienstleistungen oder uns selbst, stehen wir dir gerne persönlich zur Verfügung. Schreib uns über das Formular und wir antworten schnellstmöglich.",
    "text-invoice-dialog": "Schreib uns über das Formular und wir helfen dir gerne bei Problemen mit deinen Rechnungen.",
    "lbl-send": "SENDEN",
    "toast-send-success": "Deine Nachricht wurde versendet. Wir melden uns in Kürze bei dir!",
    "toast-general-error": "Ein Fehler ist aufgetreten. Bitte versuche es noch einmal",
    "message": {
      "required": "Bitte Nachricht eingeben"
    }
  },
  "en": {
    "title-send-support-request": "Send Support Request",
    "text-support-dialog": "If you have any questions about our services or ourselves, we are happy to assist you personally. Write to us via the form and we will respond as soon as possible.",
    "text-invoice-dialog": "Write to us via the form and we will help you with any problems with your invoices.",
    "lbl-send": "SEND",
    "toast-send-success": "Your message has been sent. We will get back to you shortly!",
    "toast-general-error": "An error occurred. Please try again",
    "message": {
      "required": "Please enter a message"
    }
  }
}
</i18n>
